import styled from 'styled-components';

const Page = styled.div`
  transition: .3s ease-in-out;
  width: 100%;
  position: relative;
  background-color: #5FB894;
  overflow: hidden;
  min-height: calc(100vh);
  padding-left: 200px;

  @media (max-width: 767px) {
    padding-left: 0;
    min-width: 100vw;
    margin-left: ${({ opened, alwaysClosed }) => !alwaysClosed && opened ? '200px' : '0px'};
  }
`;

export default Page;