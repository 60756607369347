// @flow
import { callFormEncoded } from '../core';

export const callLogin = (email: string, password: string) => {
  return callFormEncoded('users/login', 'POST', { body: { email, password } });
};

export const callResetLink = (email: string) => {
  return callFormEncoded('users/reset', 'POST', { body: { email } });
};

export const callChangePassword = (token: string, password: string) => {
  return callFormEncoded(`users/reset/${token}`, 'POST', { body: { password } });
};
