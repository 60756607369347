import styled from 'styled-components';
import "typeface-montserrat";

const PromptText = styled.h2`
height: ${({ small }) => small ? 13 : 24}px;	
width: 54px;	
color: red;	
font-family: Montserrat;	
font-size: 12px;	
font-weight: 300;	
line-height: 24px;	
text-align: center;
width: 100%
margin: 0px;
`;

export default PromptText;
