import React from 'react';
import styled from 'styled-components';
import "typeface-montserrat";

import SidebarToggler from '../SidebarToggler';

const PageHeader = styled.h2`
    height: 37px;	
    width: 83px;	
    color: #354148;	
    font-family: Montserrat;	
    font-size: 30px;	
    font-weight: 600;	
    line-height: 37px;
`;

export default ({ children }) => (
    <div>
        <SidebarToggler />
        <PageHeader>{children}</PageHeader>
    </div>
);