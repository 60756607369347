import styled from "styled-components";
import "typeface-playfair-display";

const LoginTitle = styled.h2`
  height: 60px;
  width: 179px;
  color: #354148;
  font-family: "Playfair Display";
  font-size: 60px;
  font-weight: bold;
  line-height: 72px;
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0;
  
  ${({ spaceless }) => spaceless
  ? 'margin: 0;'
  : 'margin-bottom: 50px;'}
`;

export default LoginTitle;
