import styled from 'styled-components';
import "typeface-montserrat";

const LoginSubtitle = styled.h2`
height: 24px;	
width: 54px;	
color: #788F9B;	
font-family: Montserrat;	
font-size: 20px;	
font-weight: 300;	
line-height: 24px;	
text-align: center;
width: 100%
margin-bottom: 50px;
`;

export default LoginSubtitle;