import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import LoadingReducer from './api/loadingReducer';
import ErrorReducer from './api/errorReducer';
import SessionReducer from './session/reducer';
import UserReducer from './user/reducer';
import DocumentsReducer from './documents/reducer';
import AppReducer from './app/reducer';
import rootSaga from '../saga';
import storage from 'redux-persist/lib/storage';

const reducers = {
  loading: LoadingReducer,
  error: ErrorReducer,
  session: SessionReducer,
  user: UserReducer,
  documents: DocumentsReducer,
  app: AppReducer
};

const rootReducer = combineReducers(reducers);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session', 'user'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware),
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
