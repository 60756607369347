// @flow
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import SideNavPage from '../../components/SideNavPage';
import PageHeader from '../../components/PageHeader';
import LoadingIndicator from '../../components/LoadingIndicator';
import PromptText from '../../components/PromptText';
import SmallButton from '../../components/SmallButton';
import ExpiresDateInput from '../../components/ExpiresDateInput';

type Props = {
  loading: boolean,
  error: string,
  documents: Object[],
  getDocumentsList: () => void,
  getDocument: (doctype: string) => void,
  addDocument: (
    doctype: string,
    file: File,
    onSuccess: Function,
    onError: Function,
  ) => void,
};

class DocumentsPage extends React.Component<Props> {
  state = {
    dates: {},
  };
  componentDidMount() {
    this.props.getDocumentsList();
  }
  handleDownload = doctype => {
    this.props.getDocument(doctype, true);
  };
  handleUpload = (doctype, event, needExpiresDate) => {
    if (needExpiresDate && !this.state.dates[doctype]) {
      return alert('Expires date is required');
    }

    let files = event.target.files;
    if (files && files.length > 0) {
      let file = files[0];
      this.props.addDocument(
        doctype,
        file,
        () => alert('Document was added successfully'),
        e => alert('There was an error while adding the document: ' + e),
        this.state.dates[doctype]
      );
    }
  };
  handleView = doctype => {
    this.props.getDocument(doctype);
  };
  handleExpiresDateChange = e => {
    const {name, value} = e.target;

    this.setState(prevState => ({
      dates: {
        ...prevState.dates,
        [name]: value,
      },
    }));
  };
  render() {
    return (
      <SideNavPage>
        <PageHeader>Documents</PageHeader>
        {this.props.loading ? (
          <LoadingIndicator />
        ) : this.props.error ? (
          <PromptText>{this.props.error}</PromptText>
        ) : (
          <Row>
            {this.props.documents.map(d => (
              <Col sm={6} lg={4} key={d.doctype + '-col'}>
                <Cell>
                  <DocTitle>
                    {d.filename} {' '}
                    {d.expires && d.expiresDate && (<small>Expires: {new Date(d.expiresDate).toLocaleDateString()}</small>)}
                  </DocTitle>
                  <Space />
                  <DocStatus status={d.status}>{d.status}</DocStatus>
                  <div>
                    {d.status === 'required' || d.status === 'rejected' ? (
                      <React.Fragment>
                        {d.type !== 'upload-only' && (
                          <SmallButton
                            onClick={() => this.handleDownload(d.doctype)}
                            key={d.doctype + '-download'}
                          >
                            Download
                          </SmallButton>
                        )}
                        {d.expires && (
                          <ExpirationDateContainer>
                            Expiration Date:
                            <ExpiresDateInput
                              type="date"
                              name={d.doctype}
                              onChange={this.handleExpiresDateChange}
                            />
                          </ExpirationDateContainer>
                        )}
                        <input
                          id={`upload-file-${d.doctype}`}
                          type="file"
                          accept="application/pdf"
                          style={{ display: 'none' }}
                          onChange={ev => this.handleUpload(d.doctype, ev, d.expires)}
                        />
                        <SmallButton
                          as="label"
                          htmlFor={`upload-file-${d.doctype}`}
                        >
                          Upload
                        </SmallButton>
                      </React.Fragment>
                    ) : (
                      <SmallButton
                        onClick={() => this.handleView(d.doctype)}
                        key={d.doctype + '-view'}
                      >
                        View
                      </SmallButton>
                    )}
                  </div>
                </Cell>
              </Col>
            ))}
          </Row>
        )}
      </SideNavPage>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading['GET_DOCUMENTS_LIST'],
  error: state.error['GET_DOCUMENTS_LIST'],
  documents: state.documents.all,
});

const mapDispatchToProps = dispatch => ({
  getDocumentsList: () => dispatch({ type: 'GET_DOCUMENTS_LIST' }),
  getDocument: (doctype: string, template: boolean) => dispatch({ type: 'GET_DOCUMENT', doctype, template }),
  addDocument: (
    doctype: string,
    file: File,
    onSuccess: Function,
    onError: Function,
    expiresDate: string,
  ) => dispatch({ type: 'ADD_DOCUMENT', doctype, file, onSuccess, onError, expiresDate }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);

const Cell = styled.div`
  margin: 0.5rem;
  padding: 1rem;
  background-color: #f3f3f3;
  border-radius: 1rem;
  height: 169px;
  display: flex;
  flex-direction: column;

  span {
    display: block;
  }
  button {
    margin-right: 0.5rem;
  }
`;

const Space = styled.div`
  flex-grow: 1;
`;

const DocTitle = styled.span`
  min-height: 50px;
`;

const DocStatus = styled.span`
  color: ${props =>
    props.status === 'required' || props.status === 'rejected'
      ? '#EE0000'
      : props.status === 'pending'
      ? '#FFAC00'
      : '#CCCCCC'};
  text-transform: capitalize;
  margin-bottom: 0.5rem;
`;

const ExpirationDateContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;
