// @flow
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  callGetDocumentsList,
  callGetDocument,
  callAddDocument,
} from '../api/documents';
import DocumentsActions from '../data/documents/actions';
import { base64toBlob, fileToBase64 } from '../util/FileUtils';

export function* getDocumentsList(action): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_DOCUMENTS_LIST_REQUEST' });
    const response: Object = yield call(callGetDocumentsList);
    yield put(DocumentsActions.setDocuments(response));
    yield put({ type: 'GET_DOCUMENTS_LIST_SUCCESS' });
  } catch (e) {
    yield put({ type: 'GET_DOCUMENTS_LIST_FAILURE', error: e.message });
  }
}

export function* getDocument(action: {
  doctype: string, template: boolean }): Generator<*, *, *> {
  try {
    yield put({ type: 'GET_DOCUMENT_REQUEST' });
    const response: Object = yield call(callGetDocument, action.doctype, action.template);
    const blob = base64toBlob(response.data, 'application/pdf');
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
    yield put({ type: 'GET_DOCUMENT_SUCCESS' });
  } catch (e) {
    yield put({ type: 'GET_DOCUMENT_FAILURE', error: e.message });
  }
}

export function* addDocument(action: {
  doctype: string,
  file: File,
  onSuccess: Function,
  onError: Function,
  expiresDate: string,
}): Generator<*, *, *> {
  try {
    yield put({ type: 'ADD_DOCUMENT_REQUEST' });
    const data = yield fileToBase64(action.file);
    const response: Object = yield call(callAddDocument, action.doctype, data, action.expiresDate);
    yield put(DocumentsActions.updateDocumentPending(action.doctype));
    yield put({ type: 'ADD_DOCUMENT_SUCCESS' });
    action.onSuccess();
  } catch (e) {
    yield put({ type: 'ADD_DOCUMENT_FAILURE', error: e.message });
    action.onError(e.message);
  }
}

const authSaga = [
  takeLatest('GET_DOCUMENTS_LIST', getDocumentsList),
  takeLatest('GET_DOCUMENT', getDocument),
  takeLatest('ADD_DOCUMENT', addDocument),
];

export default authSaga;
