import React from 'react';
import { connect } from 'react-redux';

import Page from '../../components/Page';
import Card from '../../components/Card';
import LoginTitle from '../../components/LoginTitle';
import LoginSubtitle from '../../components/LoginSubtitle';
import Input from '../../components/Input';
import PromptText from '../../components/PromptText';
import Button from '../../components/Button'
import { FormContainer } from '../LoginPage';

class ForgotPasswordPage extends React.Component {
  state = {
    email: '',
    error: '',
    sent: false,
  };

  onSuccessfulMail = () => {
    this.setState({ sent: true });
  };

  handleEmailInput = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.sendResetLink(this.state.email, this.onSuccessfulMail);
  };

  render() {
    const { email, error, sent } = this.state;

    console.log(this.props);
    return (
      <Page alwaysClosed>
        <Card>
          <FormContainer>
            <form>
              <LoginTitle spaceless>Visitry</LoginTitle>
              <LoginSubtitle>Reset Password</LoginSubtitle>
              <Input
                type="email"
                refs="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={this.handleEmailInput}
              />
              <PromptText small success={sent && !error}>
                {sent && !error ? 'Email has been sent successfully' : error}
              </PromptText>
              <PromptText small>{this.props.error}</PromptText>
              <Button
                onClick={this.handleSubmit}
                theme="dark"
                loading={this.props.loading}
              >
                Send Reset Link
              </Button>
            </form>
          </FormContainer>
        </Card>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  data: state,
  loading: state.loading['SEND_RESET_LINK'],
  error: state.error['SEND_RESET_LINK'],
});

const mapDispatchToProps = dispatch => ({
  sendResetLink: (email: string, onSuccess: () => void) => dispatch({ type: 'SEND_RESET_LINK', email, onSuccess }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
