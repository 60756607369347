import React from 'react';
import { Row } from 'react-grid-system';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from '../Page';
import Card from '../Card';
import SideNavSection from '../SideNavSection';
import { iconDashboard, iconLogout } from '../../assets/images';

const SideNavPage = props => {
  let { location, history } = props;
  let currentRoute = location.pathname;
  const topButtons = [
    {
      title: 'Documents',
      icon: iconDashboard,
      route: '/',
    },
  ];
  const bottomButtons = [
    {
      title: 'Logout',
      icon: iconLogout,
      route: '/logout',
    },
  ];
  const handleNavigationChange = value => {
    if (value === '/logout') {
      props.logout();
      history.push('/');
    } else {
      let page = value;
      history.push(page);
    }
  };

  return (
    <Page opened={props.opened}>
      <Row>
        <SidebarWrapper>
          <MenuMainTitle style={{ width: 10 }}>Visitry</MenuMainTitle>
          <MenuSubtitleText>Welcome Back, {props.userName}</MenuSubtitleText>
          <SideNavSection
            isVertical
            buttons={topButtons}
            onChange={handleNavigationChange}
            route={currentRoute}
          />
          <Space />
          <SideNavSection
            isVertical
            buttons={bottomButtons}
            onChange={handleNavigationChange}
            route={currentRoute}
          />
        </SidebarWrapper>
        <Card style={{ paddingTop: 0, paddingLeft: 30 }}>
          <InnerCard>{props.children}</InnerCard>
        </Card>
      </Row>
    </Page>
  );
};

const mapStateToProps = state => ({
  userName: state.user.name,
  opened: state.app.opened,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: 'LOGOUT' }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNavPage));

const MenuMainTitle = styled.h2`
  height: 60px;
  width: 179px;
  color: white;
  font-family: 'Playfair Display';
  font-size: 40px;
  font-weight: bold;
  line-height: 72px;
  position: relative;
  width: 100%;
  text-align: start;
  margin: 0;
  margin-left: -160px;
`;

const MenuSubtitleText = styled.h6`
  width: 112px;
  color: #dee2e4;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  text-align: center;
  margin-left: -155px;
`;

const InnerCard = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;
  background-color: white;
  padding: ${props => props.padding || '20px'};
  overflow: hidden;
  margin-top: 0px;
  margin-right: 30px;
`;

const SidebarWrapper = styled.div`
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Space = styled.div`
  flex-grow: 1;
`;
