import React from 'react';
import { Row } from 'react-grid-system';
import styled from 'styled-components';

const SideNavSection = ({
  center = false,
  buttons,
  route,
  onChange,
  ishidden,
}) => {
  const handleChange = (e, value) => {
    e.preventDefault();
    onChange(value);
  };
  return (
    <Wrapper>
      {buttons.map(button => {
        let isActive = false;
        if (Array.isArray(route)) {
          isActive = route.indexOf(button.route) > -1;
        } else {
          isActive = route === button.route;
        }
        return (
          <MenuItem
            key={button.route}
            center={center}
            onClick={e => handleChange(e, button.route)}
            active={isActive}
          >
            <img src={button.icon} alt="nav"></img>
            <label style={{ cursor: 'pointer' }}>{button.title}</label>
          </MenuItem>
        );
      })}
    </Wrapper>
  );
};

export default SideNavSection;

const Wrapper = styled.div`
  border: none;
  margin-left: -160px;
  align-items: start;
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.button`
  height: 35px;
  width: 130px;
  border-radius: 17.5px;
  background-color: ${props => (props.active ? '#38887E' : '#5FB894')};
  color: ${props =>
    props.active ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.4)'};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  text-align: start;
  border: 0px solid;
  margin-right: 10px;
  margin-bottom: 20px;
  outline: none !important;
  outline-offset: none !important;
  cursor: pointer;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  display: flex;

  img {
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    opacity: ${props => (props.active ? '1.0' : '0.4')};
  }
`;
