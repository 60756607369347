import React from 'react';
import styled from 'styled-components';
import 'typeface-montserrat';
import LoadingIndicator from '../LoadingIndicator';

const Styled = styled.button`
  height: 40px;
  width: 100%;
  border-radius: 20px;
  background-color: ${props =>
  props.theme === 'light' ? '#F7F8F8' : '#354148'};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: ${props => (props.theme === 'light' ? '#B0BEC5' : 'white')};
  margin-top: 30px;
  border: 0px;
  outline: none !important;
  outline-offset: none !important;
`;

const StyledButton = styled(Styled)`
  cursor: pointer;
`;

const Button = props => {
  if (props.loading) {
    return (
      <Styled as="div" theme={props.theme} style={{ paddingTop: 5 }} style={props.style}>
        <LoadingIndicator />
      </Styled>
    );
  } else {
    return (
      <StyledButton type='button' theme={props.theme} style={props.style} onClick={props.onClick}>
        {props.children}
      </StyledButton>
    );
  }
};

export default Button;
