import React from 'react';
import PromptText from '../components/PromptText';

export const FormErrors = ({formErrors}) =>
  <div style = {{margin:0,padding:0}}>
    {Object.keys(formErrors).map((fieldName, i) => {
      if(formErrors[fieldName].length > 0){
        var name;

        if(fieldName==='password')
        {
          name = 'Password';
        }
        else
        {
          name = 'Email';
        }
        return (
          <PromptText key={i}>{name} {formErrors[fieldName]}</PromptText>
        )        
      } else {
        return <PromptText></PromptText>;
      }
    })}
  </div>