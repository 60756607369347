import styled from 'styled-components';
import 'typeface-montserrat';

const SmallButton = styled.button`
  display: inline-block;
  height: 30px;
  border-radius: 14.5px;
  background-color: white;
  color: #b0bec5;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  border: 0px solid;
  padding: 0px 10px;
  cursor: pointer;
`;

export default SmallButton;
