import * as ActionTypes from './actionTypes';

const initialState = {
  id: null,
  email: null,
  name: null,
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        id: action.id,
        email: action.email,
        name: action.name,
      };
    case ActionTypes.CLEAR_USER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
