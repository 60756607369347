import styled from 'styled-components';
import 'typeface-montserrat';

const ExpiresDateInput = styled.input`
  margin-right: 15px;
  font-family: Montserrat;
  font-size: 14px;
`;

export default ExpiresDateInput;
