import * as ActionTypes from './actionTypes';

const setDocuments = documents => ({
  type: ActionTypes.SET_DOCUMENTS,
  documents,
});

const updateDocumentPending = doctype => ({
  type: ActionTypes.UPDATE_DOCUMENT_PENDING,
  doctype,
});

export default {
  setDocuments,
  updateDocumentPending,
};
