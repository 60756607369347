import { createGlobalStyle } from 'styled-components';
import { setConfiguration } from 'react-grid-system';

// Global grid style setting
setConfiguration({
  containerWidths: [540, 849, 849, 849],
  gutterWidth: 20,
});

const GlobalStyle = createGlobalStyle`
  /* Global styles */
  * {
    box-sizing: border-box;
  }
  #root {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: Lato;
    overflow-x: hidden;
    background-color: #5FB894;
  }
`;

export default GlobalStyle;