import React from 'react';
import { connect } from 'react-redux';

import Page from '../../components/Page';
import Card from '../../components/Card';
import LoginTitle from '../../components/LoginTitle';
import LoginSubtitle from '../../components/LoginSubtitle';
import Input from '../../components/Input';
import PromptText from '../../components/PromptText';
import Button from '../../components/Button'
import { FormContainer } from '../LoginPage';

class ForgotPasswordPage extends React.Component {
  state = {
    password: '',
    passwordRepeat: '',
    error: '',
  };

  handlePasswordChange = (field, e) => {
    this.setState({
      [field]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { password, passwordRepeat } = this.state;

    if (!password || !passwordRepeat) {
      this.setState({
        error: 'All fields are required',
      });

      return;
    }

    if (password !== passwordRepeat) {
      this.setState({
        error: 'Passwords do not match',
      });

      return;
    }

    this.props.savePasswords(this.props.match.params.token, password);
  };

  render() {
    const { password, passwordRepeat, error } = this.state;

    return (
      <Page alwaysClosed>
        <Card>
          <FormContainer>
            <form>
              <LoginTitle spaceless>Visitry</LoginTitle>
              <LoginSubtitle>Create New Password</LoginSubtitle>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => this.handlePasswordChange('password', e)}
              />
              <PromptText small />
              <Input
                type="password"
                name="password"
                placeholder="Repeat Password"
                value={passwordRepeat}
                onChange={(e) => this.handlePasswordChange('passwordRepeat', e)}
              />
              <PromptText small>{error}</PromptText>
              <PromptText small>{this.props.error}</PromptText>
              <Button
                onClick={this.handleSubmit}
                theme="dark"
                loading={this.props.loading}
              >
                Save Password
              </Button>
            </form>
          </FormContainer>
        </Card>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading['SAVE_PASSWORDS'],
  error: state.error['SAVE_PASSWORDS'],
});

const mapDispatchToProps = dispatch => ({
  savePasswords: (token: string, password: string) => dispatch({ type: 'SAVE_PASSWORDS', token, password }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
