import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginPage from '../pages/LoginPage';
import DocumentsPage from '../pages/DocumentsPage';
import GlobalStyle from './GlobalStyle';
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";

const AuthRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      )
    }
  />
);

const UnauthRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )
    }
  />
);

const Routes = props => (
  <div style={{ overflow: 'hidden', maxWidth: '100vw'}}>
    <GlobalStyle />
    <Switch>
      <AuthRoute
        path="/"
        exact
        component={DocumentsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <UnauthRoute
        path="/login"
        exact
        component={LoginPage}
        isAuthenticated={props.isAuthenticated}
      />
      <UnauthRoute
        path="/forgot"
        exact
        component={ForgotPasswordPage}
        isAuthenticated={props.isAuthenticated}
      />
      <UnauthRoute
        path="/reset/:token"
        exact
        component={ResetPasswordPage}
        isAuthenticated={props.isAuthenticated}
      />
    </Switch>
  </div>
);

const mapStateToProps = state => ({
  isAuthenticated: Boolean(state.session.accessToken) && state.session.expire >= Date.now(),
});

export default connect(mapStateToProps)(Routes);
