const initialState = {
    opened: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_SIDEBAR':
            return {
                ...state,
                opened: action.payload,
            }
        default:
            return state;
    } 
}

export default reducer;