import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import closeIcon from '../../assets/images/close.png';
import menuIcon from '../../assets/images/menu.png';

import AppActions from '../../data/app/actions';

const Button = styled.button`
    width: 35px;
    height: 35px;
    border-radius: 20px;
    padding: 5px;
    display: none;
    border: none;
    align-items: center;
    justify-content: center;

    img {
        max-width: 16px;
        max-height: 16px;
    }

    @media (max-width: 767px) {
        display: flex;
    }
`;

const SidebarToggler = ({ opened, toggle }) => (
    <Button onClick={() => toggle(!opened)}>
        <img src={opened ? closeIcon : menuIcon} alt="Sidebar" />
    </Button>
);

const mapStateToProps = state => ({
    opened: state.app.opened,
})

const mapDispatchToProps = dispatch => ({
    toggle: (payload) => dispatch(AppActions.toggleSidebar(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarToggler);