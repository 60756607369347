import styled from 'styled-components';
import "typeface-montserrat";

const Input = styled.input`
    height: 53px;	
    width: 100%;	
    border: 0px;
    padding-left: 10px;
    border-radius: 5px;	
    background-color: #F7F8F8;
    font-family: Montserrat;	
    font-size: 16px;	
    line-height: 19px;

    &::-webkit-input-placeholder {
        color: #B0BEC5;
      }
`;

export default Input;