import * as ActionTypes from './actionTypes';

const initialState = {
  all: [],
};

export default function documents(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_DOCUMENTS:
      return {
        ...state,
        all: action.documents,
      };
    case ActionTypes.UPDATE_DOCUMENT_PENDING:
      return {
        ...state,
        all: state.all.map(d => {
          if (d.doctype === action.doctype) {
            return {
              ...d,
              status: 'pending',
            };
          }
          return d;
        }),
      };
    default:
      return state;
  }
}
