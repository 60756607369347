// @flow
import { call, callRaw } from '../core';

export const callGetDocumentsList = () => {
  return call('documents', 'GET', {});
};

export const callGetDocument = (doctype: string, template?: boolean) => {
  return callRaw('documents', 'GET', { params: { doctype, template } });
};

export const callAddDocument = (doctype: string, dataBase64: string, expiresDate: string) => {
  return callRaw('documents', 'POST', {
    params: { doctype, expiresDate },
    body: { file: dataBase64 },
  });
};
