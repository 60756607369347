/* @flow */
import { call, put, takeLatest } from 'redux-saga/effects';
import SessionActions from '../data/session/actions';
import UserActions from '../data/user/actions';
import {callChangePassword, callLogin, callResetLink} from '../api/users';
import decode from 'jwt-decode';

function* authenticateUser(user: Object): Generator<void, void, void> {
  const decoded = decode(user.token);
  const expire = decoded.exp * 1000;

  yield put(UserActions.setUser(user._id, user.email, user.name));
  yield put(SessionActions.setToken(user.token, expire));
}

export function* login(action: {
  email: string,
  password: string,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'LOGIN_REQUEST' });
    const response: Object = yield call(
      callLogin,
      action.email,
      action.password,
    );
    yield authenticateUser(response.user);
    yield put({ type: 'LOGIN_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'LOGIN_FAILURE',
      error:
        error.code === 400 ? 'Invalid username or password' : error.message,
    });
  }
}

export function* logout(): Generator<void, void, void> {
  yield put(UserActions.clearUser());
  yield put(SessionActions.clearToken());
}

export function* sendResetLink(action: {
  email: string,
  onSuccess: () => void,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'SEND_RESET_LINK_REQUEST' });
    const response: Object = yield call(callResetLink, action.email);
    yield put({ type: 'SEND_RESET_LINK_SUCCESS' });
    yield call(action.onSuccess);
  } catch (error) {
    yield put({
      type: 'SEND_RESET_LINK_FAILURE',
      error: error.message,
    });
  }
}

export function* savePasswords(action: {
  token: string,
  password: string,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'SAVE_PASSWORDS_REQUEST' });
    const response: Object = yield call(callChangePassword, action.token, action.password);
    yield put({ type: 'SAVE_PASSWORDS_SUCCESS' });
    window.location.href = '/login';
  } catch (error) {
    yield put({
      type: 'SAVE_PASSWORDS_FAILURE',
      error: error.message,
    });
  }
}

const authSaga = [
  takeLatest('LOGIN', login),
  takeLatest('LOGOUT', logout),
  takeLatest('SEND_RESET_LINK', sendResetLink),
  takeLatest('SAVE_PASSWORDS', savePasswords),
];

export default authSaga;
