// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Card from '../../components/Card';
import Page from '../../components/Page';
import LoginTitle from '../../components/LoginTitle';
import Input from '../../components/Input';
import Button from '../../components/Button';
import PromptText from '../../components/PromptText';
import { FormErrors } from '../../validation/FormErrors';
import LinkText from "../../components/LinkText";

export const FormContainer = styled.div`
  max-width: 500px;
  width: calc(100% - 30px);
`;

type Props = {
  login: (email: string, password: string) => void,
  loading: boolean,
  error: string,
};

class LoginPage extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      emailErrors: { email: '' },
      passwordErrors: { password: '' },
      loginpressed: false,
      emailValid: false,
      passwordValid: false,
      formValid: false,
    };
  }

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case 'email':
        this.setState({ [name]: value }, () => {
          this.validateEmailField(value);
        });
        break;
      case 'password':
        this.setState({ [name]: value }, () => {
          this.validatePasswordField(value);
        });
        break;
      default:
        break;
    }
  };

  validatePasswordField(value) {
    let fieldValidationErrors = this.state.passwordErrors;
    let passwordValid = this.state.passwordValid;
    let buttonpressed = this.state.loginpressed;

    passwordValid = value.length > 0;

    if (buttonpressed)
      fieldValidationErrors.password = passwordValid ? '' : ' is required';

    this.setState(
      {
        passwordErrors: fieldValidationErrors,
        passwordValid: passwordValid,
      },
      this.validateForm,
    );
  }

  validateEmailField(value) {
    let fieldValidationErrors = this.state.emailErrors;
    let emailValid = this.state.emailValid;
    let buttonpressed = this.state.loginpressed;

    emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    if (buttonpressed)
      fieldValidationErrors.email = emailValid ? '' : ' is invalid';

    this.setState(
      {
        emailErrors: fieldValidationErrors,
        emailValid: emailValid,
      },
      this.validateForm,
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid && this.state.passwordValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? '' : 'has-error';
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loginpressed: true }, this.checkFields);
  };

  checkFields = () => {
    this.validatePasswordField(this.state.password);
    this.validateEmailField(this.state.email);
    if (this.state.formValid) {
      this.props.login(this.state.email, this.state.password);
    }
  };

  render() {
    return (
      <Page alwaysClosed>
        <Card>
          <FormContainer>
            <form>
              <LoginTitle>Visitry</LoginTitle>
              <Input
                type="email"
                refs="email"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleUserInput}
              />
              <FormErrors formErrors={this.state.emailErrors} />
              <Input
                type="password"
                refs="password"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleUserInput}
              />
              <FormErrors formErrors={this.state.passwordErrors} />
              <LinkText to="/forgot">Forgot Username/Password</LinkText>
              <PromptText>{this.props.error}</PromptText>
              <Button onClick={this.handleSubmit}>Login</Button>
            </form>
          </FormContainer>
        </Card>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading['LOGIN'],
  error: state.error['LOGIN'],
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch({ type: 'LOGIN', email, password }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
